.email-container {
    max-width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 30px;
    border: 1px solid #ddd;
}

.app-card {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.app-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.app-info {
    display: flex;
    flex-direction: column;
}

.app-title {
    font-size: 16px;
    margin: 0;
    color: #333;
}

.app-company {
    font-size: 14px;
    color: #777;
}

.notification {
    border: 1px solid #4CAF50;
    background-color: #fefefe;
    padding: 10px;
    margin-top: 20px;
}

.content {
    margin-top: 20px;
    line-height: 1.7;
    color: #333;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}

.feedback-link {
    color: #4285F4;
    text-decoration: none;
    font-size: 14px;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

.button {
    background-color: #E0E0E0;
    color: white;
    padding: 12px 24px;
    text-decoration: none;
    border-radius: 2px;
    font-size: 14px;
    font-weight: bold;
    box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.footer {
    margin-top: 30px;
    text-align: center;
    font-size: 10px;
    color: #888;
}

.footer a {
    color: #888;
    text-decoration: none;
}

.custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9ecf0; /* Màu của Facebook */
    color: #000000; /* Màu chữ */
    font-size: 14px; /* Kích thước chữ */
    padding: 0.5rem;
    margin: 0 auto;
    width: fit-content;
    border: none; /* Loại bỏ viền */
    border-radius: 30px; /* Bo tròn góc */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Hiệu ứng bóng */
    transition: box-shadow 0.3s; /* Hiệu ứng chuyển động */
    width:50%;
}

.custom-button:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Tăng độ nổi khi hover */
}

.custom-button:active {
    box-shadow: none; /* Loại bỏ hiệu ứng bóng khi click */
    transform: translateY(1px); /* Di chuyển button xuống một chút khi click */
}

.banner-content {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff !important;
}

.banner {
    position: relative;
    padding: 50px 0;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-position: center bottom;
    background-image: url("./img/banner.png");
}

.banner-content1 {
    background-color: #243760b3;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 50px 0;
    height: 100%;
    width: 100%;
    border-radius: 30px; /* Bo tròn góc */

}


.react-tel-input .form-control {
    width: 100% !important;
    height: 16% !important;
}

@media only screen and (max-width: 600px) {
    .mb-4 {
        margin-bottom: 1rem; /* Thay đổi margin cho điện thoại */
    }
    .space-x-0 {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
        margin-left: calc(1.5rem * var(--tw-space-x-reverse) * -1);
    }
    .md\:space-x-2 {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
        margin-left: calc(0.5rem * var(--tw-space-x-reverse) * -1);
    }
    .md\:mb-8 {
        margin-bottom: 2rem; /* Thay đổi margin cho điện thoại */
    }
    .custom-button {
        /* Thêm kiểu cho nút trên điện thoại */
        background-color: #e9ecf0; /* Màu của Facebook */
        color: #000000;
        padding: 10px 15px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        border: none;
        width: 100%;
    }
    .custom-button img {
        margin-right: 10px; /* Thay đổi margin cho hình ảnh trên điện thoại */
    }
}

